import React, { Component } from 'react';
import Loadable from 'react-loadable';
import './App.css';
import './orgMode.css';
import './index.css';
import articleMeta from './Articles/ArticleMeta'
import ParentMenu from './Menu/Menu.jsx';
import TitleBar from './TitleBar/TitleBar.jsx';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-134214267-1');

function TextContent(props) {
    const contentMap = {
        "home": "StartPage",
        "articles": "Articles",
        "aboutme": "AboutMe",
        "disclaimer": "Disclaimer",
        "contact": "Contact"
    };

    const contentStateHandler = props.contentHandler;

    let ContentModule = null;
    if (props.content in contentMap) {
        const contentImport = contentMap[props.content];

        ContentModule = Loadable({
            loader: () => import(`./${contentImport}/${contentImport}`),
            loading: () => <div>Loading...</div>
        });
    } else if (props.content in articleMeta) {
        const contentImport = articleMeta[props.content].module;
        ContentModule = Loadable({
            loader: () => import(`./Articles/Texts/${contentImport}/${contentImport}`),
            loading: () => <div>Loading...</div>
        });
    } else {
        const contentImport = "StartPage";
        ContentModule = Loadable({
            loader: () => import(`./${contentImport}/${contentImport}`),
            loading: () => <div>Loading...</div>
        });
    }
    return (
            <div className="text-content">
            <ContentModule contentHandler={(cont) => contentStateHandler(cont)}/>
            <div style={{marginTop: "30px", marginBottom: "30px"}}>
            <center>
            <p>
            <a href="https://www.patreon.com/albertheinle">Support this blog on Patreon</a>
            </p>
            </center>
            </div>
            </div>
    );
}

class App extends Component {

    constructor(props) {
        super(props);
        this.currentUrl = new URL(window.location.href);
        this.state = {
            content: this.currentUrl.searchParams.get("content") || "home"
        };
        window.onpopstate = this.onPopStateHandler;
    }

    onPopStateHandler = (event) => {
        this.currentUrl = new URL(window.location.href);
        this.setState({
            content: this.currentUrl.searchParams.get("content") || "home"
        });
    }

    contentHandler(cont) {
        ReactGA.pageview(window.location.href+window.location.search);
        this.currentUrl.searchParams.set("content", cont);
        window.history.pushState({}, "", this.currentUrl);
        this.setState({
            content: cont
        });
    }

    render() {
        ReactGA.pageview(window.location.href+window.location.search);
        return (
                <div className="App">
                <TitleBar contentHandler={(cont) => this.contentHandler(cont)}/>
                <ParentMenu contentHandler={(cont) => this.contentHandler(cont)} />
                <TextContent content={this.state.content}
            contentHandler={(cont) => this.contentHandler(cont)}/>
                </div>
        );
    }
}

export default App;
