const articleMeta = {
    "smallChunks": {
        module: "A20180901SmallChunks",
        date: new Date(2018, 8, 1),
        thumbnailTitle: "My Personal Key Technique to Finishing Big Projects",
        thumbnailPreview: `Here is an expression I have read in
almost every piece of literature on time or project management`
    },
    "secureYourApps": {
        module: "A20180920SecureYourApps",
        date: new Date(2018, 8, 20),
        thumbnailTitle: "Unfortunately not Standard: Force Users to Securely Configure your Applications!",
        thumbnailPreview: "Have a look at the following news articles"
    },
    "scriptIt": {
        module: "A20180923ScriptIt",
        date: new Date(2018, 8, 23),
        thumbnailTitle: "If You Liked It, Then You Shoulda Put a Script To It...",
        thumbnailPreview: "Let us make a bet: If you are a developer, I bet you have"
    },
    "20181108Hierarchies": {
        module: "A20181108Hierarchies",
        date: new Date(2018, 10, 8),
        thumbnailTitle: "The Myths About Correct Hierarchy Models",
        thumbnailPreview: "I remember, when I first started to learn about job-interview do's and don'ts, there was"
    },
    "20181122ApacheArrow": {
        module: "A20181122ApacheArrow",
        date: new Date(2018, 10, 22),
        thumbnailTitle: "All Hail Apache Arrow",
        thumbnailPreview: "Back in 2010, the Dremel whitepaper by Google was inspiring developers to create the ubiquitous"
    },
    "20181206ACallForNonAttention": {
        module: "A20181206ACallForNonAttention",
        date: new Date(2018, 11, 6),
        thumbnailTitle: "Crazy Articles and a Call for Non-Attention",
        thumbnailPreview: "To Whomever Brought Back the Flat Earth Hypothesis"
    },
    "20181220ThankMeLaterPartOne": {
        module: "A20181220ThankMeLaterPartOne",
        date: new Date(2018, 11, 20),
        thumbnailTitle: "Thank Me Later Part One",
        thumbnailPreview: "I will present tips for your software/produc development that I have collected over the years."
    },
    "20190110JuliaMomentIsHere": {
        module: "A20190110JuliaMomentIsHere",
        date: new Date(2019, 0, 10),
        thumbnailTitle: "Julia, Your Moment Is Here... Seize It!",
        thumbnailPreview: "It is not really news any more that the so-called \"benevolent dictator for life\" Guido van Rossum has retired from his role"
    },
    "20190124HowLanguageIsProgrammingUs": {
        module: "A20190124HowLanguageIsProgrammingUs",
        date: new Date(2019, 0, 24),
        thumbnailTitle: "How language is programming us",
        thumbnailPreview: "A while ago I was introduced to an outstanding German website: neusprech.org."
    },
    "20190321StatusQuoSolving": {
        module: "A20190321StatusQuoSolving",
        date: new Date(2019, 2, 21),
        thumbnailTitle: "The Status Quo of Solving",
        thumbnailPreview: "Solve for x; One of the most common tasks that everyone of us had to perform during their time in high school."
    },
    "20190404PipeDreamMath": {
        module: "A20190404PipeDreamMath",
        date: new Date(2019, 3, 4),
        thumbnailTitle: "The Pipe Dream about Mathematical Competitions",
        thumbnailPreview: "I remember quite vividly when I started to study mathematics. It was"
    },
    "20190520WhenToStartFresh": {
        module: "A20190520WhenToStartFresh",
        date: new Date(2019, 4, 20),
        thumbnailTitle: "When to start from scratch... and when not...",
        thumbnailPreview: "When a team of engineers is assigned the task to build something new"
    },
    "20190610Empathy": {
        module: "A20190610Empathy",
        date: new Date(2019, 5, 10),
        thumbnailTitle: "On Analytics and Empathy",
        thumbnailPreview: "Despite knowing that many of you will roll their eyes at the chosen subject, I am going to write this article anyway"
    },
    "20190711GenchiGenbutsu": {
        module: "A20190711GenchiGenbutsu",
        date: new Date(2019, 6, 11),
        thumbnailTitle: "Genchi Genbutsu and the Birthday Paradox",
        thumbnailPreview: "I am not intending to play buzzword bingo on Japanese lean terms in this blog article. But the principle"
    },
    "20190915Haikus": {
        module: "A20190915Haikus",
        date: new Date(2019, 8, 15),
        thumbnailTitle: "Haiku Fun",
        thumbnailPreview: "I love to Express myself sometimes in alternative ways than just writing a text"
    },
    "20191105ThankMeLater2": {
        module: "A20191105ThankMeLater2",
        date: new Date(2019, 10, 5),
        thumbnailTitle: "Thank me Later: Part Two",
        thumbnailPreview: "Roughly a year ago, I wrote the first part of this series, and since then I collected more items I would like to share with my readers"
    },
    "20191217PracticalTheory": {
        module: "A20191217PracticalTheory",
        date: new Date(2019, 11, 17),
        thumbnailTitle: "There is nothing more practical than a good theory",
        thumbnailPreview: "The title sentence, when communicated to a group of people, is usually causing mixed reactions"
    },
    "20200421Butterfly": {
        module: "A20200421Butterfly",
        date: new Date(2020, 3, 21),
        thumbnailTitle: "The butterfly effects in our lives",
        thumbnailPreview: "For years, I have to admit to an accumulated guilt. I was not understanding and sometimes ridiculing",
    },
    "20200804LinuxConfigs": {
        module: "A20200804LinuxConfigs",
        date: new Date(2020, 7, 4),
        thumbnailTitle: "Commonly suggested Linux configuration changes for scaling purposes",
        thumbnailPreview: "Software fails at times, especially when users start using it",
    },
    "20201015GoodEnough": {
        module: "A20201015GoodEnough",
        date: new Date(2020, 9, 15),
        thumbnailTitle: "The problem with \"good enough\"",
        thumbnailPreview: "A while ago I came across an interesting art collection",
    },
    "20210107InfinityGems": {
        module: "A20210107InfinityGems",
        date: new Date(2021, 0, 7),
        thumbnailTitle: "The six infinity stones of programming",
        thumbnailPreview: "There are a lot of superb books, going into detail on how to create great code and manage software projects",
    },
};

export default articleMeta;
