import React from 'react';
import "./Menu.css";

function MenuItem(props) {
    let style = {
        left: "calc(var(--distance-to-left) + " + props.index + " * var(--menu-item-width))"
    };
    if (window.innerWidth < 800 ) {
        style = {
            left: "var(--distance-to-left)",
            top: "calc(var(--title-bar-height) + " + props.index + " * var(--height-of-menu-items) + 5px)"
        };
    }

    const contentHandler = () => {
        props.contentHandler(props.link);
    };
    
    return (
          <div className="menu-item" style={style} onClick={contentHandler}>
            {props.name}
          </div>
    );
}

function BurgerIcon(props) {
    return (
        <div className="burger-menu" onClick={props.toggleState}>
          <div className="burger-menu-bar" />
          <div className="burger-menu-bar" />
          <div className="burger-menu-bar" />
        </div>
    );
}

class ParentMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: false
        };
        this.contentHandler = props.contentHandler;
        window.addEventListener("resize", () => {
            this.forceUpdate();
        });
    }

    toggleState() {
        this.setState({display: !this.state.display});
    }

    toggleContent(cont) {
        this.contentHandler(cont);
    }
    
    render() {
        if (window.innerWidth < 800) {
            const currentStyle = {
                display: (this.state.display? "block" : "none")
            };
            return (
                <div className="parent-menu">
                  <BurgerIcon toggleState={this.toggleState.bind(this)}/>
                  <Menu toggleState={this.toggleState.bind(this)}
                        contentHandler={(cont) => this.toggleContent(cont)}
                        style={currentStyle} />
                </div>
            );
        } else {
            return (
                <div className="parent-menu">
                  <Menu contentHandler={(cont) => this.toggleContent(cont)} />
                </div>
            );
        }
    }
}

class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.menuItems = {
            "Articles": "articles",
            "About Me": "aboutme",
            "Disclaimer": "disclaimer",
            "Contact": "contact"
        };

        this.contentHandler = props.contentHandler;
        this.toggleMenuVisibilityState = props.toggleState;
    }

    toggleContent(cont) {
        this.contentHandler(cont);
    }

    render() {
        return (
            <div
              className="menu" style={this.props.style}
              onClick={this.toggleMenuVisibilityState}>
              {Object.keys(this.menuItems).map( (key, index) => {
                  return (<MenuItem name={key}
                                       key={key}
                                       link={this.menuItems[key]}
                                       index={index}
                                    contentHandler={(cont => this.toggleContent(cont))} />
                         );
              })}
            </div>
        );
    }
}

export default ParentMenu;
