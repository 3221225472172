import React from 'react';
import "./TitleBar.css";

class TitleBar extends React.Component {
    render() {
        return (
            <div className="title-bar" onClick={() => this.props.contentHandler("home")}>
            </div>
        );
    }
}

export default TitleBar;
